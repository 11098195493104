<template>
  <div class="home">
    <HeaderNavigation  class="animate__animated animate__bounceInDown" :info="info" :scale="scale" />
    <!-- <div style="display: flex;">
      <div :style="'width:'+SwiperBox_width+'px'">
        <SwiperBox class="animate__animated animate__bounceInUp" :imgList="imgList"></SwiperBox>
      </div>
      <div :style="'width:'+img_width+'px;position: absolute;z-index: 9;right: 0px;height: calc(100vh - 80px);;'">
        <img class="animate__animated animate__bounceInUp" src="../../assets/yushen/right-img.png" style="height:100%;width:100%"/>
      </div>
    </div> -->
    
    <div style="display: flex;padding-top:50px;" id="shouye">
      <img :style="'width:40px;height:'+SwiperBox_height+'px;'" src="../../assets/yushen/left-banner.png" />
      <div :style="'width:'+SwiperBox_width+'px;'">
        <SwiperBox class="animate__animated animate__bounceInUp" :imgList="imgList" :height="SwiperBox_height"></SwiperBox>
      </div>
      <div :style="'width:'+img_width+'px;position: absolute;z-index: 9;right: 0px;height: '+SwiperBox_height+'px;'">
        <img class="animate__animated animate__bounceInUp" src="../../assets/yushen/right-img.png" style="height:100%;width:100%"/>
      </div>
    </div>

    <div  id="pinpai" >
       <div class="two-content">
          <div class="two-left"></div>
          <div class="two-right">
            <div style="padding:0 50px 0 20px;">
               <div class="two-title">品牌介绍</div>
               <div class="two-in">BRAND INTRODUCTION</div>
               <!-- <div class="two-text">“鱼神”脆皮烤鱼是广州金舌头餐饮品牌管理有限公司旗下新建品牌，品牌创始人为彭警锋（网站上线时间：2023年9月13号）</div> -->
               <div class="two-text">
                <!-- “鱼神”脆皮烤鱼选择以烤鱼这一款城市年轻人晚饭和夜宵几乎都会选择的特色菜作为切入口，结合当下地方特色美食文化以新额独特的产品模式和亲切个性化的服务，做出了一条属于自己的餐饮之路。 -->
                鱼神脆皮烤鱼创始团队，经历半年时间的打磨，选择以烤鱼为突破口，结合当下地方特色美食文化,创立了“鱼神脆皮烤鱼”品牌
              </div>
               <div class="two-text">
                总部整体实力深厚，有着多年的餐饮经营经历，专业的生产研发部门，持续产品研发创新，总部统一购置及派送原料，核心技术传授，有助于提高店铺的客流量。
               </div>
               <div class="two-text">
                “鱼神”脆皮烤鱼是可信赖的品牌加盟店，企业致力于搜罗优质食材，走新型特色文化路线，为现代人提供更多营养、健康、好味的餐食，也让加盟商运营起来更为轻松。
               </div>
            </div>
            <!-- <div style="width:calc( (100% - 80px) * 0.4);text-align: center;" >
              <img style="width:80%;height:80%" src="../../assets/yushen/two/4.png" />
            </div> -->
            
          </div>
       </div>
       <div class="two-bottom"></div>
    </div>
    <!-- <div id="mendian" style="position: relative;" >
       <div class="three-content">
          <div class="three-left"></div>
          <div class="three-right">
            <div  >
              <img style="height:50px;width:200px"  src="../../assets/yushen/three/5.png" />
              <img style="height:250px;width:200px" src="../../assets/yushen/three/3.png" />

            </div>
            <div style="width:calc( (100% - 80px) * 0.6);" >
              <div class="swiper-bg">
                <SwiperPc  :imgList="shop_img" style="width:92%;height:123px;margin-top:8px"></SwiperPc>
              </div>
            </div>
          </div>
       </div>
       <img style="width: 85%;height: 8vh;position: absolute;bottom: 10px;right: 8%;" src="../../assets/yushen/three/6.png" />
       <div class="three-bottom" >
          <img style="width:85%;height:70%" src="../../assets/yushen/three/6.png" />
       </div>
    </div> -->
    <div class="four-content"  id="caipin"  >
      <div class="four-left"></div>
        <img class="four-img" src="../../assets/yushen/four/2.png" />
    </div>
    <div class="five-content"  id="hezuo">
      <div class="five-left"></div>
        <img class="five-img1" src="../../assets/yushen/five/3.png" />
        <img class="five-img2" src="../../assets/yushen/five/4.png" />
    </div>
    <div class="six-content" id="xinwen">
      <div class="six-left"></div>
    </div>
    <div class="seven-content" style="position: relative;">
            <div class="seven-block">
              <div class="seven-item">
                <img @click="toPointer('pinpai')" src="../../assets/yushen/seven/pinpai.png" />
                <div class="seven-text">品牌文化</div>
              </div>
              <div class="seven-item">
                <img @click="toPointer('hezuo')" src="../../assets/yushen/seven/hezuo.png" />
                <div class="seven-text">加盟流程</div>
              </div>
              <div class="seven-item">
                <img @click="toPointer('caipin')" src="../../assets/yushen/seven/caipin.png" />
                <div class="seven-text"  >研创招牌</div>
              </div>
              <div class="seven-item">
                <img @click="toPointer('mendian')" src="../../assets/yushen/seven/mendian.png" />
                <div class="seven-text">研创招牌</div>
              </div>
              <div class="seven-item">
                <img @click="toPointer('xinwen')" src="../../assets/yushen/seven/xinwen.png" />
                <div class="seven-text">供应链</div>
              </div>
            </div>
        <!-- <BottomNav></BottomNav> -->
    </div>
    <LeftMenu :info="info"></LeftMenu>
    <RightMenu  :info="info"  @openpop="openpop" />
    <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
  </div>
</template>

<script>
// @ is an alias to /src
import {indexBanner,indexData,configshow} from '@/api/index.js'
import HeaderNavigation from "@/components/header_navigation.vue";
import RightMenu from "@/components/right-menu.vue";
import LeftMenu from "@/components/left-menu.vue";
import SwiperBox from "@/components/swiper.vue";
import SwiperPc from "@/components/swiper-pc.vue";
import JoinBulletFrame from "@/components/joinBulletFrame.vue";
import BottomNav from "@/components/bottom_navigation.vue"
export default {
  name: "Home",
  components: {
    HeaderNavigation,
    RightMenu,
    SwiperBox,
    SwiperPc,
    JoinBulletFrame,
    LeftMenu,
    BottomNav
  },

  data() {
    return {
      info:{},
      JoinModal:false,
      productModal:false,
      productImg:'',
      glory:[],
      news:[],
      product:[],
      us:{},
      imgList:[],
      shop_img:[],
      default_width:1920,
      scale:1,
      SwiperBox_width:"",
      SwiperBox_height:"",
      img_width:""
    };
  },
  mounted() {
    this.indexBanner()
    // this.indexData()
    this.configshow()
    
    this.init()
    let _this = this;
    window.addEventListener('resize',function(){
      _this.init()
    })
  },
  methods:{
    
    init(){
       let width = window.innerWidth;
       this.scale = width / this.default_width
      //  this.scale = 0.4
       this.SwiperBox_width = width * 0.75
       this.SwiperBox_height = this.SwiperBox_width / 1.7
       this.img_width = width * 0.35
    },
    configshow(){
      configshow().then(res=>{
        this.info = res.data
        this.$store.commit('setInfos',  this.info);
      })
    },
    indexBanner(){
      indexBanner().then(res=>{
        this.imgList = res.data['banner']
        this.shop_img = res.data['shop']
      })
    },
    indexData(){
      indexData().then(res=>{
        this.glory = res.data.glory
        this.news = res.data.news
        this.product = res.data.product
        this.us = res.data.us
      })
    },
    openpop(e){
      this.JoinModal = e
    },
    closepop(e){
      this.JoinModal = e
    },
    toPointer(e){
      location.href = "#"+e
    }
  }
};
</script>

<style scoped>
.two-content{
  display: flex; 
  height: 300px
}
.two-left{
  width:40px;
  height:100%;
  background-image: url('../../assets/yushen/two/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.two-right{
  width:calc(100% - 40px);
  height:100%;
  background-image: url('../../assets/yushen/two/5.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  padding-top:20px;
  justify-content:space-between;
}

.two-bottom{
  width:100%;
  height:54px;
  background-image: url('../../assets/yushen/two/2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.two-title{
  color:rgb(0, 100, 47);font-size:20px;font-weight: bold;
}
.two-in{
  color:#ff0000;font-size:15px;font-weight: bold
}
.two-text{
  color:rgb(0, 100, 47);
  font-size: 12px;
  font-weight: 500;
  margin-top:10px
}


.three-content{
  display: flex; 
  height:300px;
}
.three-left{
  width:40px;
  height:300px;
  background-image: url('../../assets/yushen/three/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.three-right{
  width:calc(100% - 40px);
  height:300px;
  background-image: url('../../assets/yushen/three/7.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content:space-between;
  text-align: center;
  align-items: center
}

.three-bottom{
  width:100%;
  height:154px;
  background-image: url('../../assets/yushen/three/4.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.swiper-bg{
  background-image: url(../../assets/yushen/three/9.png);
  height: 200px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat; 
  display: flex;
  justify-content: center;
  margin-top:10px;
}
.four-content{
  background-image: url('../../assets/yushen/four/3.png');
  height: 300px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;

}
.four-left{
  width:40px;
  height:300px;
  background-image: url('../../assets/yushen/four/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.four-img{
  width:120px;
  height:40px;
  margin:30px 0 0 0px
}


.five-content{
  background-image: url('../../assets/yushen/five/2.png');
  height: 300px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;

}
.five-left{
  width:40px;
  height:300px;
  background-image: url('../../assets/yushen/five/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.five-img1{
  width:76px;
  height:54px;
  margin:50px 0 0 0;
}
.five-img2{
  width:205px;
  height:57px;
  margin:20px 0 0 0;
}
.six-content{
  background-image: url('../../assets/yushen/six/2.png');
  height: 300px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;

}
.six-left{
  width:40px;
  height:300px;
  background-image: url('../../assets/yushen/six/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.seven-content{
  background-image: url('../../assets/yushen/seven/1.png');
  height: 300px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;

}
.seven-block{
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  float: right;
  margin:100px 80px 0 0;
}
.seven-item{
  text-align: center;
}
.seven-item img{
  cursor: pointer;
}
.seven-text{
  margin-top:30px;
  color:#747474;
  cursor: pointer;
}
.seven-text:hover{
  color:#fff
}
</style>
